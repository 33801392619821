<template>
  <v-card>
    <v-card-title>
      <h2>Total Game List</h2>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      ref="gameTable"
      :headers="headers"
      :items="allGames"
      :search="search"
      class="elevation-1"
      item-key="theKey"
    >
      <template slot="items" slot-scope="props">
        <tr
          :key="itemKey(props.item.game)"
          class="sortableRow"
          :class="isActive(props.item.game)"
        >
          <td class="px-1" style="width: 0.1%">
            <v-btn icon class="sortHandle" @click="directGame(props.item.game)"
              ><v-icon>flight_takeoff</v-icon></v-btn
            >
          </td>
          <td class="text-xs-left">
            <a @click="pushToGameEdit(props.item)"
              >{{ props.item.game.name }}
            </a>
          </td>
          <td class="text-xs-left">{{ props.item.game.date }}</td>
          <td class="text-xs-left">{{ getOrg(props.item.game.orgID) }}</td>
          <td class="text-xs-left">
            {{ props.item.game.client }} (Added by
            {{ props.item.user.firstname }} )
          </td>
          <td class="text-xs-left">{{ props.item.numOfPlayers }}</td>
          <td class="text-xs-left">
            <a
              :href="`/gamelogin/${getKey(props.item.game)}?auth=0`"
              target="_blank"
            >
              Login
            </a>
            <br />
            <a
              :href="`/gamelogin/${getKey(props.item.game)}?audit=1&auth=0`"
              target="_blank"
            >
              <i>Audit</i>
            </a>
          </td>

          <td class="text-xs-center" width="280px">
            <v-btn
              link
              color="white"
              light
              small
              fab
              @click="directGame(props.item)"
            >
              <v-icon>flight_takeoff</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        From {{ pageStart }} to {{ pageStop }}
      </template>
    </v-data-table>

    <v-dialog v-model="deleting" max-width="500px">
      <v-card>
        <v-toolbar color="highlight" dark>
          <v-toolbar-title> Delete Game</v-toolbar-title>
        </v-toolbar>
        <v-card-text
          >Are you sure want to delete the game called <b>{{ game.name }}</b
          >?</v-card-text
        >
        <v-card-actions>
          <v-layout justify-center>
            <v-btn flat class="error" @click="remove">Remove</v-btn>
            <v-btn flat class="secondary" @click="deleting = false"
              >Cancel</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="editing" v-if="game" width="60%">
      <GameSettings
        :copying="copying"
        :adding="adding"
        :game="game"
        @closeGameSettings="closeEdit()"
      />
    </v-dialog>
    <v-dialog width="768px">
      <v-card>
        <v-card-text>
          <v-toolbar color="highlight" dark>
            <v-icon v-if="copying">open_in_new</v-icon>
            <v-icon v-else dark>edit</v-icon>

            <v-toolbar-title> {{ game.name }}</v-toolbar-title>
            <v-spacer />
            <v-spacer />
            <v-btn icon @click="update">
              <v-icon>saves</v-icon>
            </v-btn>
            <v-btn icon @click="editing = false">
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <div v-if="game">
            <v-layout row wrap>
              <v-flex xs12 sm5 mx-2>
                <v-text-field v-model="game.name" label="Name" required />
              </v-flex>
              <v-flex xs12 sm5 mx-2>
                <v-text-field
                  v-model="game.description"
                  label="Description"
                  required
                />
              </v-flex>
              <v-flex xs10 mx-2>
                <v-text-field
                  v-model="game.pickTeamText"
                  label="Pick Team Text"
                  required
                />
              </v-flex>
              <v-flex xs4 mx-2>
                <v-text-field v-model="game.date" label="Game Date" required />
              </v-flex>
              <v-flex xs4 mx-2>
                <v-text-field v-model="game.client" label="Client" required />
              </v-flex>
              <v-flex xs5 sm2 mx-2>
                <v-select
                  :items="listTabs"
                  v-model="game.runStatus"
                  label="Game Status"
                  required
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm2 mx-2>
                <v-switch v-model="game.locked" label="Locked" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-switch v-model="game.started" label="Started" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-switch v-model="game.testing" label="Testing" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-switch v-model="game.audio" label="Play Audio " />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-text-field v-model="game.potato" label="Potato" />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-text-field v-model="game.players" label="Num of players" />
              </v-flex>
            </v-layout>
            <v-layout v-if="!game.group" row wrap>
              <v-flex xs12 sm2 mx-2>
                <v-select
                  :items="playerPointsGoal"
                  v-model="game.playerPointsGoal"
                  label="Player Goal"
                  required
                />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-select
                  :items="maxTeamSize"
                  item-value="value"
                  item-text="name"
                  v-model="game.maxTeamSize"
                  label="Max Players Per Team"
                  required
                />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-select
                  :items="maxNumOfTeams"
                  item-value="value"
                  item-text="name"
                  v-model="game.maxNumOfTeams"
                  label="Max # of Teams"
                  required
                />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-select
                  :items="tries"
                  v-model="game.numOfTries"
                  label="Default Tries"
                  required
                />
              </v-flex>
              <v-flex xs12 sm2 mx-2>
                <v-text-field
                  v-model="game.themeColor"
                  label="Hex Code"
                  required
                />
              </v-flex>
              <v-flex xs12 sm1 mx-2 />
              <v-flex v-if="false" xs12 sm5 mx-2>
                <v-img
                  :src="game.image"
                  style="float: left; margin-right: 10px"
                  width="125"
                  @click="game.image = null"
                />
                <image-uploader
                  :debug="1"
                  :max-width="1200"
                  :quality="1"
                  :auto-rotate="true"
                  :preview="false"
                  :class-name="[
                    'fileinput',
                    { 'fileinput--loaded': game.image }
                  ]"
                  output-format="verbose"
                  capture="environment"
                  accept=".jpg,.png,.jpeg"
                  @input="setImage($event, game, 'image')"
                >
                  <label ref="fileGrabber" slot="upload-label" for="fileInput">
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          class="path1"
                          d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                        />
                      </svg>
                    </figure>
                    <span class="upload-caption">{{
                      game.image ? "Replace Game Image" : "Upload Game Image"
                    }}</span>
                  </label>
                </image-uploader>
              </v-flex>
            </v-layout>
            <v-flex v-if="user.super && copying" xs12 mx-2>
              <v-select
                v-model="game.orgID"
                :items="orgs"
                label="Copy To Organization"
                item-text="name"
                item-value="id"
              />
            </v-flex>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-center>
            <!-- <v-flex v-if="copying" xs3>
              <v-switch v-model="game.copyPlay" label="Copy Play Data" />
            </v-flex> -->
            <v-btn v-if="copying" flat class="primary" @click="update"
              >Copy</v-btn
            >
            <v-btn
              v-else
              :loading="loadingImage"
              flat
              class="primary"
              @click="update"
              >Save</v-btn
            >
            <v-btn flat class="secondary" @click="closeEdit()">Cancel</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import uniqid from "uniqid"
import * as moment from "moment"
import { uploadMedia, getBlobFromDataURL } from "@/services/storage.service"
const GameSettings = () => import("@/components/Game/GameSettings")

export default {
  metaInfo: {
    title: "Games"
  },
  components: {
    GameSettings
  },
  filters: {
    truncate: function (value, limit) {
      if (value.length > limit) {
        value = value.substring(0, limit - 3) + "..."
      }
      return value
    }
  },
  data() {
    return {
      tries: ["Unlimited", "1", "2", "3", "4", "5"],
      tab: 0,
      org: 0,
      tabs: [
        "All",
        "Client Games",
        "Testing",
        "Templates",
        "User Templates",
        "Blocks",
        "Masters",
        "Tournament",
        "Archived",
        "Deleted"
      ],
      listTabs: [
        "Client Games",
        "Testing",
        "Templates",
        "User Templates",
        "Blocks",
        "Masters",
        "Tournament",
        "Archived",
        "Deleted"
      ],
      hasImage: false,
      imageName: "",
      imageUrl: "",
      imageFile: "",
      loadingImage: false,
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      game: {
        name: "",
        description: "",
        maxTeamSize: 6,
        maxNumOfTeams: 3
      },
      viewing: false,
      items: [],
      deleting: false,
      editing: false,
      adding: false,
      copying: false,
      theTime: moment(new Date()).unix(),
      tmp: "",
      search: "",
      pagination: {},

      behaviors: this.$store.getters.behaviors,
      playTypes: this.$store.getters.playTypes,
      playType: "Team: Speed Matters",
      headers: [
        {
          sortable: false
        },
        {
          text: "Game",
          value: "name",
          align: "left",
          sortable: false
        },
        {
          text: "Date",
          value: "date",
          align: "left",
          sortable: false
        },
        {
          text: "Org",
          value: "orgID",
          align: "left",
          sortable: false
        },
        {
          text: "Client",
          value: "client",
          align: "left",
          sortable: false
        },
        {
          text: "Active Players",
          value: "activePlayers",
          align: "left",
          sortable: false
        },
        {
          text: "Game Login",
          value: "code",
          align: "left",
          sortable: false
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters("auth", ["token"]),
    user() {
      return this.$store.getters.user
    },
    orgs() {
      return this.$store.getters.orgs
    },
    allGames() {
      if (this.$store.getters.events) {
        var arr = Object.values(this.$store.getters.events)
        // console.log('load data')
        var obj = this.$store.getters.events
        var i = 0

        for (var key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (key.length > 10) {
              // console.log('setting thekey ' + key)
              Object.assign(arr[i], { theKey: key })
            }
          }
          i++
        }
        // if (this.orgs[this.org] != "All") {
        //    arr = arr.filter(game => this.tabs[this.tab] == game.runStatus)
        // } else {
        //   arr = arr.filter(game => game.runStatus != "Deleted")
        // }
        // console.log("ARR<", arr )
        return arr.reverse()
      }
      return []
    },
    maxTeamSize() {
      var p = []
      for (var i = 1; i < 16; i++) {
        p.push({ name: i, value: i })
      }
      return p
    },
    playerPointsGoal() {
      var p = []
      for (var i = 0; i < 2001; i = i + 25) {
        p.push(i)
      }
      return p
    },
    maxNumOfTeams() {
      var p = []
      for (var i = 1; i < 20; i++) {
        p.push({ name: i, value: i })
      }
      return p
    }
  },
  watch: {
    editing(to) {
      this.cancel(to)
    },
    viewing(to) {
      this.cancel(to)
    },
    deleting(to) {
      this.cancel(to)
    }
  },
  async created() {
    await this.$store.dispatch("loadEvents")
  },
  mounted() {
    this.$refs.gameTable.defaultPagination.rowsPerPage = 50
  },
  methods: {
    getOrg(orgID) {
      if (this.orgs[orgID]) return this.orgs[orgID].name
    },
    getKey(game) {
      return game.clientID
    },
    numOfPlayers(p) {
      if (p.activePlayers > 0) return p.activePlayers + "/" + p.players
      if (!p.activePlayers) return p.players
    },
    isActive(g) {
      if (g.activePlayers > 2 && g.timeStamp + 100 > this.theTime) {
        return "isActive"
      } else {
        return null
      }
    },
    async setImage(e, game, which) {
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.loadingImage = true
        const fileName = `gamephotos/${uniqid()}-${this.user.username}.png`
        const url = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        if (which === "image") {
          game.image = url
        } else {
          game.hero = url
        }
        console.log("which type = " + which)
        this.hasImage = true
        this.loadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    async pushToGameEdit({ theKey: gameID, clientID }) {
      if (!gameID) throw new Error("No game ID")
      if (!clientID) throw new Error("No client ID")
      await this.$store.dispatch("auth/initializeToGame", { gameID, clientID })
      await this.$router.push(`/game/${clientID}/settings`)
    },
    async directGame({ theKey: gameID, clientID }) {
      if (!gameID) throw new Error("No game ID")
      if (!clientID) throw new Error("No client ID")
      await this.$store.dispatch("auth/initializeToGame", { gameID, clientID })
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey)
      return this.itemKeys.get(item)
    },
    resetForm() {
      this.game = {
        name: "",
        avatar: ""
      }
    },
    cancel(to) {
      if (to === false) {
        this.resetForm()
      }
    },
    view(game) {
      this.viewing = true
      this.game = game
    },
    closeModalDetail() {
      this.viewing = false
      this.game = null
    },
    openRemoveDialog(game) {
      this.game = game
      this.deleting = true
    },
    remove() {
      this.$store.dispatch("Games/removeGame", this.game)
      this.deleting = false
    },
    edit(game) {
      this.copying = false
      this.editing = true
      this.game = game
    },
    closeEdit() {
      this.copying = false
      this.editing = false
    },
    copy(game) {
      this.copying = true
      this.editing = true
      this.game = game
    },
    add() {
      this.editing = true
      this.adding = true
      this.game = {
        name: "",
        description: "",
        group: true
      }
    },
    update() {
      console.log("updating game")
      this.game.addedBy = this.user.name
      if (this.adding) {
        this.$store.dispatch("Games/addGame", this.game)
      } else if (this.copying) {
        this.$store.dispatch("Games/copyGame", this.game)
      } else {
        this.$store.dispatch("Games/updateGame", this.game)
      }
      this.editing = false
      this.adding = false
    },
    save() {
      this.game.avatar = "http://via.placeholder.com/480x200"
      this.game.id = this.items.length + 1
      this.items.push(this.game)
      this.resetForm()
      this.adding = false
      console.log("saving")
      this.$store.dispatch("updateGames")
    }
  }
}
</script>
